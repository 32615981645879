import { Form, useSearchParams } from '@remix-run/react'
import { cx } from 'class-variance-authority'
import { useEffect } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

interface Props {
  /** Used to generate unique query params in case multiple tables are used on the same page */
  id?: string

  defaultRowsPerPage?: number
  totalRows: number
  className?: string
}

export const DEFAULT_ROWS_PER_PAGE = 25
export const AppPagination: React.FC<Props> = ({ totalRows, className, id: name, defaultRowsPerPage = DEFAULT_ROWS_PER_PAGE }) => {
  const rowsPerPageParamName = name ? `${name}_perPage` : `perPage`
  const pageParamName = name ? `${name}_page` : `page`

  const [params, updateSearchParams] = useSearchParams()

  const rowsPerPage = Number(params.get(rowsPerPageParamName) ?? defaultRowsPerPage)
  const page = Number(params.get(pageParamName) ?? 1)
  const totalPages = Math.ceil(totalRows / rowsPerPage)

  useEffect(() => {
    if (totalPages && page > totalPages) {
      params.set(pageParamName, '1')
      updateSearchParams(params)
    }
  }, [page, pageParamName, params, totalPages, updateSearchParams])

  const onThisPage = {
    start: (page - 1) * rowsPerPage + 1,
    end: Math.min(page * rowsPerPage, totalRows)
  }

  const options = Array.from(new Set([rowsPerPage, 25, 50, 75, 100]))

  if (!totalRows) {
    return null
  }

  return (
    <div className={cx('mb-1 flex justify-end', className)}>
      <Form
        method='GET'
        onChange={(e) => {
          const rowsPerPage = e.currentTarget[rowsPerPageParamName].value
          const page = e.currentTarget[pageParamName].value

          if (rowsPerPage) {
            params.set(rowsPerPageParamName, rowsPerPage)
            params.set(pageParamName, '1')
          }

          if (page) params.set(pageParamName, page)
          updateSearchParams(params)
        }}
        className='flex items-center'
      >
        <label className='flex items-center'>
          <span className='text-xs font-medium text-gray-600'>Rows per page:</span>
          <select
            name={rowsPerPageParamName}
            defaultValue={rowsPerPage}
            // We want to remove focus from the element after it has been used
            onChange={(e) => e.currentTarget.blur()}
            className='w-fit border-none bg-transparent py-0 pr-7 font-brand text-base italic text-black'
          >
            {options.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </select>
        </label>
        <span className='mr-5 min-w-[75px] text-center font-brand text-base italic text-black'>
          {onThisPage.start}–{onThisPage.end} of {totalRows.toLocaleString('en-US')}
        </span>
        <div className='flex items-center gap-6'>
          <button
            type='button'
            name={pageParamName}
            value={page - 1}
            onClick={() => {
              params.set(pageParamName, (page - 1).toString())
              updateSearchParams(params)
              document.querySelector('.main-content')?.scrollTo({ top: 0 })
            }}
            aria-label='Previous page'
            disabled={page === 1}
            className={cx({
              'opacity-50': page === 1
            })}
          >
            <FiChevronLeft size='24px' />
          </button>
          <button
            type='button'
            name={pageParamName}
            value={page + 1}
            onClick={() => {
              params.set(pageParamName, (page + 1).toString())
              updateSearchParams(params)
              document.querySelector('.main-content')?.scrollTo({ top: 0 })
            }}
            aria-label='Next page'
            disabled={page >= totalPages}
            className={cx({
              'opacity-50': page === totalPages
            })}
          >
            <FiChevronRight size='24px' />
          </button>
        </div>
      </Form>
    </div>
  )
}
