import type { ContactAttribute, TicketField } from '@prisma/client'
import { ContactType, CustomFieldDataType } from '@prisma/client'
import { SurveyOptionType } from '@survey-builder'
import { z } from 'zod'

import { DEFAULT_ROWS_PER_PAGE } from '~/components/AppPagination'

export const PaginationSchema = z.object({
  page: z.coerce.number().gte(1).optional().default(1),
  perPage: z.coerce.number().gte(1).lte(100).optional().default(DEFAULT_ROWS_PER_PAGE),
  order: z.enum(['asc', 'desc']).optional().default('desc'),
  orderBy: z.string().optional()
})

export const unlayerJsonSchema = z.object({
  design: z.any(),
  chunks: z.any(),
  html: z.string()
})
export type UnlayerJsonSchema = z.infer<typeof unlayerJsonSchema>

export const preprocessUnlayerJsonSchema = z.preprocess((a) => JSON.parse(a as string), unlayerJsonSchema)

// this is the shape of the meta JSON column
export const surveyQuestionMetaSchema = z.object({
  type: z.nativeEnum(SurveyOptionType),
  options: z.array(z.object({ label: z.string() })),
  results: z.record(z.string(), z.number()).optional()
})

/**
 * Gets the options from a Zod enum, which may be deeply nested
 */
export const getOptionsFromZodEnum = <T>(zodEnum: z.ZodTypeAny): T[] => {
  if (zodEnum instanceof z.ZodEnum) {
    return zodEnum.options as T[]
  }

  if (zodEnum._def && zodEnum._def.innerType) {
    return getOptionsFromZodEnum(zodEnum._def.innerType)
  }

  return []
}

export const getContactImportSchema = ({ typeDefault }: { typeDefault: ContactType }) =>
  z.array(
    z.object({
      email: z.string().email(),
      prefix: z.string().nullish(),
      firstName: z.string().nullish(),
      middleName: z.string().nullish(),
      lastName: z.string().nullish(),
      suffix: z.string().nullish(),
      singleName: z.string().nullish(),
      legalName: z.string().nullish(),
      mobilePhone: z.string().nullish(),
      landlinePhone: z.string().nullish(),
      addressLine1: z.string().nullish(),
      addressLine2: z.string().nullish(),
      addressCity: z.string().nullish(),
      addressState: z.string().nullish(),
      addressZip: z.string().nullish(),
      addressZipPlus4: z.string().nullish(),
      county: z.string().nullish(),
      type: z.preprocess((v) => (v ? v : typeDefault), z.nativeEnum(ContactType))
    })
  )

export const selectOptionsSchema = z.object({
  options: z.array(
    z.object({
      value: z.string(),
      label: z.string().nullable().optional()
    })
  ),
  undefinedValueLabel: z.string().nullable().optional(),
  freeForm: z.boolean().optional()
})
export type SelectOptionsSchema = z.infer<typeof selectOptionsSchema>

export const customFieldCurrencyRangeSchema = z.object({ gte: z.coerce.number().step(0.01), lte: z.coerce.number().step(0.01).optional() })

export const customFieldAddressSchema = z.object({
  line1: z.string().nullable().optional(),
  line2: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  state: z.string().nullable().optional(),
  zip: z.string().nullable().optional()
})

export const contactNameSchema = z.object({
  prefix: z.string().nullable().optional(),
  first_name: z.string().nullable().optional(),
  middle_name: z.string().nullable().optional(),
  last_name: z.string().nullable().optional(),
  suffix: z.string().nullable().optional(),
  single_name: z.string().nullable().optional(),
  legal_name: z.string().nullable().optional(),
  abbreviation: z.string().nullable().optional()
})
export type ContactNameSchema = z.infer<typeof contactNameSchema>

export const getOptionsForCustomField = <T extends TicketField | ContactAttribute>(customField: T) => {
  const schema = z.discriminatedUnion('dataType', [
    z.object({
      dataType: z.literal(CustomFieldDataType.MultiSelect),
      selectOptions: selectOptionsSchema
    }),
    z.object({
      dataType: z.literal(CustomFieldDataType.SingleSelect),
      selectOptions: selectOptionsSchema
    })
  ])

  const options = schema.safeParse(customField)

  if (options.success) {
    return options.data
  }
}

export const identityAddressSchema = customFieldAddressSchema.extend({
  zipplus4: z.string().nullable().optional(),
  county: z.string().nullable().optional()
})
export type IdentityAddressSchema = z.infer<typeof identityAddressSchema>

export const imageOrInnerHtmlOrSelfClosingTagRegex = /(img)|(?<=>)([\s\S]+)(?=<\/)|(?:<[^<]+\/>)/

export const attachmentSchema = z.array(
  z.object({
    name: z.string().nonempty(),
    url: z.string().nonempty(),
    size: z.coerce.number().positive()
  })
)

export type AttachmentSchemaType = z.infer<typeof attachmentSchema>

export const customerGeoJsonSchema = z.object({
  type: z.literal('FeatureCollection'),
  features: z.array(
    z.object({
      type: z.literal('Feature'),
      properties: z.object({}),
      geometry: z
        .object({
          type: z.literal('Point'),
          coordinates: z.array(z.number())
        })
        .or(
          z.object({
            type: z.literal('Polygon'),
            coordinates: z.array(z.array(z.array(z.number())))
          })
        )
        .or(
          z.object({
            type: z.literal('MultiPolygon'),
            coordinates: z.array(z.array(z.array(z.array(z.number()))))
          })
        )
    })
  )
})

export type CustomerGeoJson = z.infer<typeof customerGeoJsonSchema>

const campaignReadyForSendBaseSchema = z.object({
  id: z.string(),
  audienceCampaigns: z.array(z.object({ audience: z.object({ snapshotEndedAt: z.date().or(z.string()) }) }))
})

export const campaignReadyForSendSchema = z.discriminatedUnion('channel', [
  campaignReadyForSendBaseSchema.extend({
    channel: z.literal('Email'),
    emailCampaign: z.object({
      subject: z.string(),
      templateJson: unlayerJsonSchema
    })
  }),
  campaignReadyForSendBaseSchema.extend({
    channel: z.literal('SMS'),
    smsCampaign: z.object({ template: z.string() })
  })
])

/**
 * Example value
 * {"bbox": "-104.95228229162358,38.0836699855019,-90.92494492784941,44.63383212537562", "type": "Segment", "zoom": "6.768483501104096", "action": "update", "precision": "5", "audience_name": "Small sample", "attributes_019f7fa7-d8d0-47a1-9234-23988555f298_Currency[gte]": "0", "attributes_019f7fa7-d8d0-47a1-9234-23988555f298_Currency[lte]": "30000"}
 */
export const segmentFiltersSchema = z.record(z.string(), z.unknown())
