// this file exists to share code between frontend code and backend code
// (otherwise you'll get an error about window not being defined)

export enum SurveyOptionType {
  SingleChoice = 'single_choice',
  MultipleChoice = 'multiple_choice'
}

export const configName = 'Survey tool'

export const generateSurveyOptionLinkMergeTag = (questionIndex: number, optionIndex: number) => {
  return `survey_selection_${questionIndex}_${optionIndex}`
}
